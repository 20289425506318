<template>
    <div>
        <div class="form" v-if="isStep(0)">
            <div class="cust-form">
                <!-- <div class="content cust-id">
                    <div class="title">取引先ID</div>
                    <span class="value">
                        <span v-if="isCreate()">
                            登録時に採番されます
                        </span>
                        <span v-else>
                            {{ d.customer_id }}
                        </span>
                    </span>
                </div> -->

                <div class="item valid-date">
                    <OpDateRangeTextField
                        label="有効期間"
                        v-model.trim="d.dates"
                        name="dates"
                        :startReadOnly="isUpdate()"
                        :endReadOnly="notEditableEndDate()"
                        required="start"
                        :mindate="minstartdate()"
                        @changed-valid="changedValid"
                        ref="dates"
                    />
                </div>
                <div class="item cust-code">
                    <OpTextField
                        label="取引先CD"
                        v-model.trim="d.customer_code"
                        maxlength="4"
                        required
                        name="customer_code"
                        ref="customer_code"
                        pattern="A9"
                        @changed-valid="changedValid"
                    />
                </div>
                <div class="item cust-name-long">
                    <OpTextField
                        label="取引先名（正式）"
                        v-model.trim="d.customer_name_long"
                        maxlength="80"
                        required
                        name="customer_name_long"
                        @changed-valid="changedValid"
                    />
                </div>
                <div class="item cust-name-short">
                    <OpTextField
                        label="取引先名（短縮）"
                        v-model.trim="d.customer_name_short"
                        maxlength="20"
                        required
                        name="customer_name_short"
                        @changed-valid="changedValid"
                    />
                </div>

                <div class="item cust-index">
                    <OpTextField
                        label="取引先インデックス"
                        v-model.trim="d.customer_index"
                        maxlength="100"
                        name="customer_index"
                        @changed-valid="changedValid"
                    />
                </div>
                <div class="item salesorder-flag">
                    <OpCheckbox
                        label="受注先"
                        v-model.trim="d.salesorder_flag"
                        maxlength="100"
                        name="salesorder_flag"
                    />
                </div>
                <div class="item purchaseorder-flag">
                    <OpCheckbox
                        label="発注先"
                        v-model.trim="d.purchaseorder_flag"
                        maxlength="100"
                        name="purchaseorder_flag"
                    />
                </div>
                <div class="item user-flag">
                    <OpCheckbox
                        label="ユーザ"
                        v-model.trim="d.user_flag"
                        maxlength="100"
                        name="user_flag"
                    />
                </div>
                <fieldset class="deposit-fieldset">
                    <legend>入金</legend>
                    <div class="deposit">
                        <div class="item deposit-cutoff-day">
                            <OpNumberTextField
                                label="入金締日"
                                v-model="d.deposit_cutoff_day"
                                min="1"
                                max="31"
                                name="deposit_cutoff_day"
                                prefix="毎月"
                                prefixwidth="2em"
                                suffix="日"
                                suffixwidth="1em"
                                maxwidth="8em"
                                @changed-valid="changedValid"
                            />
                        </div>
                        <div class="item deposit-term">
                            <OpNumberTextField
                                label="入金サイト"
                                v-model="d.deposit_term"
                                min="0"
                                max="12"
                                name="deposit_term"
                                suffix="ヵ月"
                                suffixwidth="2em"
                                maxwidth="7em"
                                @changed-valid="changedValid"
                            />
                        </div>
                        <div class="item deposit-day">
                            <OpNumberTextField
                                label="入金日"
                                v-model="d.deposit_day"
                                min="1"
                                max="31"
                                name="deposit_day"
                                suffix="日"
                                suffixwidth="1em"
                                maxwidth="6em"
                                @changed-valid="changedValid"
                            />
                        </div>
                        <div class="item deposit-flag">
                            <OpCheckbox
                                label="後日シフト"
                                v-model.trim="d.deposit_later_date_flag"
                                name="deposit_later_date_flag"
                            />
                        </div>
                        <div class="item deposit-bank">
                            <OpSelectBank
                                label="入金口座（銀行）"
                                v-model="d.deposit_bank"
                                maxlength="80"
                                name="deposit_bank"
                                @changed-valid="changedValid"
                            />
                        </div>
                    </div>
                </fieldset>
                <fieldset class="payment-fieldset">
                    <legend>支払</legend>
                    <div class="payment">
                        <div class="item payment-cutoff-day">
                            <OpNumberTextField
                                label="支払締日"
                                v-model="d.payment_cutoff_day"
                                min="1"
                                max="31"
                                name="payment_cutoff_day"
                                prefix="毎月"
                                prefixwidth="2em"
                                suffix="日"
                                suffixwidth="1em"
                                maxwidth="8em"
                                @changed-valid="changedValid"
                            />
                        </div>
                        <div class="item payment-term">
                            <OpNumberTextField
                                label="支払サイト"
                                v-model="d.payment_term"
                                min="0"
                                max="12"
                                name="payment_term"
                                suffix="ヵ月"
                                suffixwidth="2em"
                                maxwidth="7em"
                                @changed-valid="changedValid"
                            />
                        </div>
                        <div class="item payment-day">
                            <OpNumberTextField
                                label="支払日"
                                v-model="d.payment_day"
                                min="1"
                                max="31"
                                name="payment_day"
                                suffix="日"
                                suffixwidth="1em"
                                maxwidth="6em"
                                @changed-valid="changedValid"
                            />
                        </div>
                        <div class="item payment-flag">
                            <OpCheckbox
                                label="後日シフト"
                                v-model.trim="d.payment_later_date_flag"
                                name="payment_later_date_flag"
                            />
                        </div>
                    </div>
                </fieldset>
                <div class="item cust-note">
                    <OpTextField
                        label="備考"
                        v-model.trim="d.customer_note"
                        type="textarea"
                        maxlength="4000"
                        name="customer_note"
                        @changed-valid="changedValid"
                    />
                </div>
            </div>
            <div class="control-box">
                <button @click="doCancel">キャンセル</button>
                <button @click="setStep(1)" v-bind:disabled="hasErrors">
                    確認
                </button>
            </div>
        </div>
        <div v-if="isStep(1)">
            <div class="content">
                <table class="confirm">
                    <tr>
                        <th>取引先ID</th>
                        <td>
                            <span class="value">
                                <span v-if="isCreate()">
                                    登録時に採番されます
                                </span>
                                <span v-else>
                                    {{ d.customer_id }}
                                </span>
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <th>取引先CD</th>
                        <td>
                            <span class="value">{{ d.customer_code }}</span>
                        </td>
                    </tr>
                    <tr>
                        <th>有効期間</th>
                        <td>
                            <span class="value">
                                {{ d.dates.start | date }} -
                                {{ d.dates.end | date }}</span
                            >
                        </td>
                    </tr>
                    <tr>
                        <th>取引先名（正式）</th>
                        <td>
                            <span class="value">{{
                                d.customer_name_long
                            }}</span>
                        </td>
                    </tr>
                    <tr>
                        <th>取引先名（短縮）</th>
                        <td>
                            <span class="value">{{
                                d.customer_name_short
                            }}</span>
                        </td>
                    </tr>

                    <tr>
                        <th>取引先インデックス</th>
                        <td>
                            <span class="value">{{ d.customer_index }}</span>
                        </td>
                    </tr>
                    <tr>
                        <th>種別</th>
                        <td>
                            <span class="value">
                                <span v-if="d.salesorder_flag">受注先</span>
                                <span v-if="d.purchaseorder_flag">受注先</span>
                                <span v-if="d.user_flag">ユーザ</span>
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <th>入金締日</th>
                        <td>
                            <span class="value"
                                >毎月 {{ d.deposit_cutoff_day }} 日</span
                            >
                        </td>
                    </tr>
                    <tr>
                        <th>入金サイト</th>
                        <td>
                            <span class="value">{{ d.deposit_term }} ヵ月</span>
                        </td>
                    </tr>
                    <tr>
                        <th>入金日</th>
                        <td>
                            <span class="value">{{ d.deposit_day }} 日</span>
                            <span v-if="d.deposit_later_date_flag"
                                >後日シフト</span
                            >
                        </td>
                    </tr>
                    <tr>
                        <th>入金口座（銀行）</th>
                        <td>
                            <span class="value" v-if="d.deposit_bank">
                                <span class="code">{{
                                    d.deposit_bank.bank_code
                                }}</span>
                                {{ d.deposit_bank.bank_name_short }}
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <th>支払締日</th>
                        <td>
                            <span class="value"
                                >毎月 {{ d.payment_cutoff_day }} 日</span
                            >
                        </td>
                    </tr>
                    <tr>
                        <th>支払サイト</th>
                        <td>
                            <span class="value">{{ d.payment_term }} ヵ月</span>
                        </td>
                    </tr>
                    <tr>
                        <th>支払日</th>
                        <td>
                            <span class="value">{{ d.payment_day }} 日</span>
                            <span v-if="d.payment_later_date_flag"
                                >後日シフト</span
                            >
                        </td>
                    </tr>
                    <tr>
                        <th>備考</th>
                        <td>
                            <span class="value">{{ d.customer_note }}</span>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="control-box">
                <button class="control-button" @click="setStep(0)">
                    戻る
                </button>
                <button
                    class="control-button"
                    v-if="isUpdate() || isChange()"
                    @click="updateData"
                    :disabled="requesting"
                >
                    登録
                </button>
                <button
                    class="control-button"
                    v-else
                    @click="addData"
                    :disabled="requesting"
                >
                    登録
                </button>
            </div>
        </div>
        <div v-if="isStep(2)" class="complete">
            <div class="message">
                取引先 (ID : {{ d.customer_id }}, 開始日 :
                {{ d.start_date | date }})
                <span v-if="mode == 'update'">の情報を修正しました。</span>
                <span v-else>を登録しました</span>
            </div>

            <div class="control-box">
                <div></div>
                <div>
                    <button @click="close">
                        閉じる
                    </button>
                </div>
                <div>
                    <button v-if="mode != 'update'" @click="moveDetail">
                        詳細
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import EditorMixin from "@/views/EditorMixin.vue";
import OpDateRangeTextField from "@/components/OpDateRangeTextField.vue";
import OpSelectBank from "@/components/OpSelectBank.vue";
import DateUtils from "@/DateUtils";

export default {
    name: "CustomerEditor",
    mixins: [EditorMixin],
    model: {
        prop: "model"
    },
    components: {
        OpDateRangeTextField,
        OpSelectBank
    },
    props: {
        model: {
            default: function() {
                return {};
            },
            type: Object
        },
        mode: String,
        hiddenViewButton: { type: Boolean, default: false }
    },
    data: function() {
        return {
            requesting: false,
            d: this.initData(this.model, this.mode),
            maxd: null
        };
    },
    methods: {
        initData: function(model, mode) {
            let ret = { ...model };
            if (mode == "change") {
                ret.start_date = null;
                ret.end_date = null;
            }
            if (ret.end_date && ret.end_date.getFullYear() > 9000) {
                ret.end_date = null;
            }
            ret.dates = {
                start: ret.start_date,
                end: ret.end_date
            };
            if (mode == "create") {
                ret.deposit_cutoff_day = 31;
                ret.deposit_term = 1;
                ret.deposit_day = 31;

                let comp = this.getCompany();
                ret.payment_cutoff_day = comp.payment_cutoff_day;
                ret.payment_term = comp.payment_term;
                ret.payment_day = comp.payment_day;
                ret.payment_later_date_flag =
                    comp.payment_later_date_flag == "1";
            }
            return ret;
        },
        isUpdate: function() {
            return this.mode == "update";
        },
        isCreate: function() {
            return this.mode == "create";
        },
        isChange: function() {
            return this.mode == "change";
        },
        makeRequestData: function() {
            this.d.start_date = this.d.dates.start;
            this.d.end_date = this.d.dates.end;
            this.d.deposit_bank_code = null;
            if (this.d.deposit_bank && this.d.deposit_bank.bank_code) {
                this.d.deposit_bank_code = this.d.deposit_bank.bank_code;
            }

            let data = this.convertToRequestData(this.d);
            if (data.end_date == null) {
                data.end_date = "99991231";
            }
            return data;
        },
        addData: function() {
            let data = this.makeRequestData();
            let url = this.makeurl("cust");
            this.requesting = true;
            this.postData(url, data, d => {
                this.d = this.initData(d);
                this.setStep(2);
                this.$emit("created-data", this.d);
            }).then(() => {
                this.requesting = false;
            });
        },
        updateData: function() {
            let data = this.makeRequestData();
            let url = this.makeurl("cust", data.customer_id, data.start_date);
            this.requesting = true;
            this.putData(url, data, d => {
                this.d = this.initData(d);
                this.setStep(2);
                if (this.isUpdate()) {
                    this.$emit("updated-data", this.d);
                } else {
                    let url1 = this.makeurl(
                        "cust",
                        this.model.customer_id,
                        this.model.start_date
                    );
                    this.readData(url1, od => {
                        this.$emit("updated-data", od);
                    });
                }
            }).then(() => {
                this.requesting = false;
            });
        },

        minstartdate: function() {
            let ret = null;
            if (this.isChange() && this.maxd) {
                if (this.maxd.end_date) {
                    ret = this.maxd.end_date;
                } else {
                    ret = this.maxd.start_date;
                }
                ret = new Date(ret.getTime());
                ret.setDate(ret.getDate() + 1);
            }
            return ret;
        },
        notEditableEndDate: function() {
            let ret = false;
            if (this.isUpdate() && this.maxd) {
                if (this.maxd.start_date) {
                    ret =
                        DateUtils.formatDate(this.d.start_date) !=
                        DateUtils.formatDate(this.maxd.start_date);
                }
            }
            return ret;
        },
        moveDetail: function() {
            this.$emit("moveto", this.d);
            this.close();
        }
    },
    // watch: {
    //     currentStep: function(value) {
    //         this.$emit("step", value);
    //     }
    // },
    created: async function() {
        await this.loadbanklist();
        if (this.isChange() || this.isUpdate()) {
            await this.readData(
                this.makeurl("cust", this.d.customer_id, "x"),
                d => {
                    this.maxd = d;
                    if (this.isChange() && d) {
                        if (d.end_date) {
                            let ed = d.end_date;
                            ed = new Date(ed.getTime());
                            ed.setDate(ed.getDate() + 1);
                            this.d.dates = { start: ed, end: null };
                        }
                    }
                }
            );
        }
    },
    mounted: function() {
        this.d.deposit_bank = this.getBank(this.d.deposit_bank_code);
        if (this.isUpdate() && this.notEditableEndDate()) {
            this.$refs.customer_code.focus();
        } else {
            this.$refs.dates.focus();
        }
    }
};
</script>

<style scoped>
.control-box {
    display: grid;
    border-top: 1px solid var(--border-color);
    padding: 10px 0;
    grid-template-columns: 200px 1fr 200px;
    justify-items: center;
}

.cust-form {
    position: relative;
    display: grid;
    grid-template-areas:
        "valid-date          valid-date          valid-date          cust-code       cust-code       empty0            empty0"
        "cust-name-long      cust-name-long      cust-name-long      cust-name-long  cust-name-long  cust-name-short   cust-name-short"
        "cust-index          cust-index          cust-index          cust-index      cust-index      cust-index        cust-index"
        "salesorder-flag     purchaseorder-flag  user-flag           empty2          empty2          empty2            empty2"
        "deposit  deposit        deposit         deposit    deposit      deposit    deposit"
        "payment  payment        payment        payment   payment            payment          payment"
        "cust-note           cust-note           cust-note           cust-note       cust-note       cust-note         cust-note";

    grid-row-gap: 10px;
    grid-column-gap: 20px;
}

.deposit-fieldset {
    grid-area: deposit;
}
.deposit {
    position: relative;
    display: grid;
    grid-template-areas: "deposit-cutoff-day  deposit-term        deposit-day         deposit-flag    deposit-bank ";

    grid-row-gap: 10px;
    grid-column-gap: 20px;
}
.payment-fieldset {
    grid-area: payment;
}
.payment {
    position: relative;
    display: grid;
    grid-template-areas: "payment-cutoff-day  payment-term        payment-day         payment-flag";
    grid-row-gap: 10px;
    grid-column-gap: 20px;
}
.cust-form .cust-attr .item {
    display: flex;
}

.cust-form .cust-id {
    grid-area: cust-id;
}
.cust-form .cust-code {
    grid-area: cust-code;
}
.cust-form .valid-date {
    grid-area: valid-date;
}
.cust-form .cust-name-short {
    grid-area: cust-name-short;
}
.cust-form .cust-name-long {
    grid-area: cust-name-long;
}
.cust-form .cust-name-long > * {
    width: 100%;
}
.cust-form .cust-index {
    grid-area: cust-index;
}
.cust-form .cust-index > * {
    width: 100%;
}

.cust-form .salesorder-flag {
    grid-area: salesorder-flag;
}
.cust-form .purchaseorder-flag {
    grid-area: purchaseorder-flag;
}
.cust-form .user-flag {
    grid-area: user-flag;
}
.cust-form .deposit-cutoff-day {
    grid-area: deposit-cutoff-day;
}
.cust-form .deposit-term {
    grid-area: deposit-term;
}
.cust-form .deposit-day {
    grid-area: deposit-day;
}
.cust-form .deposit-flag {
    grid-area: deposit-flag;
    align-self: center;
}
.cust-form .deposit-bank {
    grid-area: deposit-bank;
}
.cust-form .payment-cutoff-day {
    grid-area: payment-cutoff-day;
}
.cust-form .payment-term {
    grid-area: payment-term;
}
.cust-form .payment-day {
    grid-area: payment-day;
}
.cust-form .payment-flag {
    grid-area: payment-flag;
    align-self: center;
}
.cust-form .cust-note {
    grid-area: cust-note;
}
.cust-form .cust-note > * {
    width: 100%;
}
</style>
