<script>
import IconBase from "@/components/icons/IconBase.vue";

export default {
    components: {
        IconBase
    },
    props: {
        disabled: { type: Boolean, default: false }
    },
    methods: {
        doClick: function(event) {
            if (!this.disabled) {
                this.$emit("click", event);
            }
        }
    }
};
</script>
<style scoped>
.actionButton {
    margin: 0ex;
    font-size: 1rem;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    padding: 0;
}
.mark {
    margin: 0;
    padding: 0;
}
.txt {
    font-size: 0.5rem;
    margin: 0;
    padding: 0;
}

.actionButton:disabled {
    opacity: 0.2;
    box-shadow: none;
    cursor: default;
}
</style>
