<template>
    <div>
        <OpSelect
            v-model="dataModel"
            :suggest="suggestBank"
            :suggestValue="suggestBankValue"
            :suggestKey="suggestBankKey"
            :required="required"
            :name="name"
            :id="id"
            :label="label"
            @changed-valid="changedValid"
            ref="input"
        />
    </div>
</template>

<script>
export default {
    name: "OpSelectBank",
    model: {
        prop: "inputModel",
        event: "input"
    },
    props: {
        inputModel: Object,
        required: Boolean,
        name: String,
        id: String,
        label: String
    },
    data: function() {
        return {
            dataModel: this.inputModel,
            modelChange: false
        };
    },
    watch: {
        dataModel: function(value) {
            if (this.modelChange) {
                this.modelChange = false;
                return;
            }
            if (value == "") {
                value = null;
            }
            this.$emit("input", value);
        },
        inputModel: function(val) {
            this.modelChange = true;
            this.dataModel = { ...val };
        }
    },
    methods: {
        focus: function() {
            this.$refs.input.focus();
        },
        suggestBank: function(query, result) {
            result.length = 0;
            for (const d of this.banklist) {
                if (query == "" || this.matchedBank(d, query)) {
                    result.push(d);
                }
            }
        },
        matchedBank: function(bank, query) {
            let qs = query.split(" ");
            let ret = false;
            let cols = ["bank_code", "bank_name_short", "bank_name_long"];
            for (let q of qs) {
                let r = false;
                for (let c of cols) {
                    r = this.includeVal(bank[c], q);
                    if (r) break;
                }
                ret = r;
                if (!ret) {
                    break;
                }
            }
            return ret;
        },
        includeVal: function(val, q) {
            return val && val.includes(q);
        },
        suggestBankValue: function(bank) {
            let ret = "";
            if (bank != null && bank.bank_code != undefined) {
                ret = bank.bank_code + " " + bank.bank_name_long;
            }
            return ret;
        },
        suggestBankKey: function(bank) {
            return bank.bank_code;
        },
        changedValid: function(name, flag) {
            this.$emit("changed-valid", name, flag);
        }
    },
    created: async function() {
        this.loadbanklist();
    }
};
</script>
